import { SEARCH_API_FORMAT_TYPES } from '@autobid/ui/constants/SEARCH_API_FORMAT_TYPES'
import type { LocationQuery } from 'vue-router'

/**
 * Filter out keys that are not filterable in the vehicle search module (API endpoint: /api/v1/search/cars)
 */
export const filterNotSearchableKeys = (data: LocationQuery) => {
  // split - look at ITEMS_TO_UPDATE_ON_TYPE_CHANGE in useSearchStore, sometimes a get parameter has a dictionary key after "-"
  const filteredEntries = Object.entries(data).filter(
    ([key]) =>
      SEARCH_API_FORMAT_TYPES[key] || SEARCH_API_FORMAT_TYPES[key.split('-')[0]]
  )

  return Object.fromEntries(filteredEntries)
}
