import type { FetchCarProps } from '@autobid/nuxt-pinia-store/types/Cars'
import type { AuctionCarsVariables } from '@autobid/ui/composables/useFetchCars'
import { getAuctionsMetaQuery } from '@autobid/ui/utils/auctions/getAuctionsMetaQuery'

type Props = {
  variables?: AuctionCarsVariables
  omits?: FetchCarProps['graphqlQueryOmits']
}

const EQUIPMENTS_WHITE_LIST = [
  17, 19, 20, 21, 24, 32, 41, 68, 70, 129, 133, 139, 144, 145, 164, 168, 174,
  226, 1143, 1157, 1162, 1163, 1218, 1241, 1242
]

const equipmentIdWhitelist = `equipmentIdWhitelist: [${EQUIPMENTS_WHITE_LIST.join(
  ','
)}]`

const getItemsQuery = () => {
  return `items {
    id
    auctionId
    auctionNumber
    name
    appId
    stage
    state
    price {
      start
      minimal
      current
    }
    category {
      id
      name
    }
    equipments
    auctionStartDate
    hotbidStartTime
    ranking
    imageGroups {
      itemMainImageSubgroup {
        links {
          hd
        }
        category
        description {
          categoryLabel
        }
      }
    }
    status {
      transportAvailable
      bmwBankFinancingAvailable
      bank11FinancingAvailable
    }
    manufacturer {
      name
    }
    catalogNumber
    isWatcher
    hasNote
    slug
    taxInformation
    additionalInformation {
      itemLocationCode
      itemLocationCountry {
        isoCode
      }
    }
    hasBid
    bids
    inActiveHotbidPhase
    hotbidDuration {
      active
    }
    isHighestBidder
  }`
}

export const getAuctionCarQuery = ({ omits }: Props) => {
  const metadataParams = omits?.counters
    ? ''
    : `
    metadataParams: {
      suppliers: ${omits?.suppliers ? 'false' : 'true'}
      counters: INTEGER
    }, `

  return `query cars($highlighted: Boolean, $pageSize: Int! = 30, $pageNumber: Int! = 0, $lang: String! = "DE", $appIds: [Int], $stages: [ItemStage!] = [BEFORE_AUCTION, IN_AUCTION, REMOVED, FINISHED], $sortBy: [SortingField!], $auctionIds: [Int!] = [], $manufacturerIds: [Int!] = [], $ids: [Int] = [], $e174: Boolean, $earliestDateOfAddition: ItemDateOfAdditionRange, $ev: Boolean, $typeCategory: [String!] = [], $manufacturerModel: [String!] = [], $e17: [Int!] = [], $e24: Int, $e70: Int, $e31: [Int!] = [], $e21: IntValueRange, $e68: IntValueRange, $e19: IntValueRange, $e223: [Int!] = [], $rankingListType: RankingListType, $supplierIds: [Int], $includeNotVisibleInList: Boolean = false, $publicationStatus: [ItemPublicationStatus!] = []) {
    items(params: {bidParams: {limit: 1}, ${metadataParams}${equipmentIdWhitelist}, pageSize: $pageSize, pageNumber: $pageNumber, lang: $lang, appIds: $appIds, stages: $stages, sortBy: $sortBy, auctionIds: $auctionIds, manufacturerIds: $manufacturerIds, ids: $ids, e174: $e174, earliestDateOfAddition: $earliestDateOfAddition, ev: $ev, typeCategory: $typeCategory, manufacturerModel: $manufacturerModel, e17: $e17, e24: $e24, e70: $e70, e31: $e31, e21: $e21, e68: $e68, e19: $e19, e223: $e223, rankingListType: $rankingListType, supplierIds: $supplierIds, includeNotVisibleInList: $includeNotVisibleInList, publicationStatus: $publicationStatus, highlighted: $highlighted}) {
      itemPageCount
      ${omits?.counters ? '' : 'metadata'}
      ${getItemsQuery()}
    }
    ${omits?.noFilterMetadata ? '' : `${getAuctionsMetaQuery(omits)}`}
  }`
}
