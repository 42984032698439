import type { LocationQuery } from 'vue-router'
import type { State } from '@autobid/nuxt-pinia-store/types/Search'
import { useHandleSearchCard } from '@autobid/ui/composables/useSearchCarQuery'
import { filterNotSearchableKeys } from '@autobid/ui/utils/filterNotSearchableKeys'
import { useUserApps } from '@autobid/ui/composables/useUserApps'

export const useGetCarIds = () => {
  const { handleSearchCard } = useHandleSearchCard()
  const { userAppIds } = useUserApps()

  const getCarIds = async (_query: LocationQuery) => {
    const query = filterNotSearchableKeys(_query)

    const resp = await handleSearchCard({
      searchValues: query as State,
      userAppIds: userAppIds.value,
      justCount: false
    })

    // 0 - to prevent sending empty array in case when user pick a filters that returns no results, then API returns empty array (there are no ids)
    return [0, ...(resp.carIds ?? []).map(Number)]
  }

  return { getCarIds }
}
