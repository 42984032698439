import type { FetchCarProps } from '@autobid/nuxt-pinia-store/types/Cars'
import type {
  CarsListResponse,
  RankingListType
} from '@autobid/ui/types/components/CarsList'
import { getAuctionCarQuery } from '@autobid/ui/utils/car/getAuctionCarQuery'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { generateImageSizesFromObject } from '@autobid/ui/utils/car/generateImageSizes'
import type { AuctionCar } from '@autobid/ui/types/Car'

type FilterRange = {
  valueFrom: number | null
  valueTo: number | null
}

export type SortByItem = {
  name: keyof AuctionCar
  order: 'ASCENDING' | 'DESCENDING'
}
export type SortBy = SortByItem[]

export type AuctionCarsVariables = {
  pageNumber?: number
  pageSize?: number
  appIds?: number[]
  auctionIds?: number[]
  manufacturerIds?: number[]
  lang?: string // is set by useI18n
  id?: number
  ids?: number[]
  manufacturerModel?: string[]
  e174?: boolean
  ev?: boolean // electric cars
  e223?: number[]
  typeCategory?: string[]
  stages?: string[]
  rankingListType?: RankingListType
  supplierIds?: number[]
  catalogNumbers?: number[]
  bidParams?: { limit: number }
  e17?: number[]
  e21?: FilterRange
  e68?: FilterRange
  e19?: FilterRange
  e24?: number
  e70?: number
  e31?: number[]
  earliestDateOfAddition?: string
  sortBy?: SortBy
  includeNotVisibleInList?: boolean
  publicationStatus?: string[]
  highlighted?: boolean
}

export function useFetchCars() {
  const headers = useRequestHeaders(['cookie'])
  const { locale } = useI18n()
  const { $customFetch } = useCustomFetch()

  const fetchCars = async (
    variables: AuctionCarsVariables,
    omits?: FetchCarProps['graphqlQueryOmits']
  ): Promise<CarsListResponse> => {
    if (variables.id) {
      variables.ids = [...(variables.ids ?? []), variables.id]
    }

    variables.lang = locale.value

    const query = getAuctionCarQuery({
      variables,
      omits
    })
    const result = await $customFetch<CarsListResponse>('/api/backend', {
      method: 'POST',
      headers,
      body: {
        queryApi: 'auctions',
        queryUrl: '/api/v1/query',
        query,
        variables: {
          pageNumber: 0,
          sortBy: {
            name: 'catalogNumber',
            order: 'ASCENDING'
          },
          ...(variables ?? {})
        }
      }
    })

    if (result?.data?.items.items.length) {
      result.data.items.items = result?.data?.items.items.map((car) => {
        return {
          hasCarsList: 1,
          ...car,
          ...(car.imageGroups
            ? {
                // only hd image is fetched - generate rest sizes
                imageGroups: generateImageSizesFromObject('hd', car.imageGroups)
              }
            : {})
        }
      })
    }

    return result
  }

  return { fetchCars }
}
