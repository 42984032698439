import type { CarImageSize, CarImage, ImageGroup } from '@autobid/ui/types/Car'

export const replaceImageSize = (
  image: string,
  sourceSize: CarImageSize,
  targetSize: CarImageSize
) => {
  return image.replace(`_${sourceSize}`, `_${targetSize}`)
}

export const generateImageSizesFromArray = (
  fromSize: CarImageSize,
  images: CarImage[]
) => {
  const SIZES: CarImageSize[] = ['hd', 'l', 'm', 's', 'xs']

  return images.map((image) => {
    return {
      ...image,
      links: SIZES.reduce((acc, size) => {
        acc[size] = replaceImageSize(image.links.hd, fromSize, size)

        return acc
      }, {}) as CarImage['links']
    }
  })
}

export const generateImageSizesFromObject = (
  fromSize: CarImageSize,
  data: Record<ImageGroup, CarImage[]>
) => {
  return Object.entries(data).reduce((acc, el) => {
    acc[el[0]] = generateImageSizesFromArray(fromSize, el[1])
    return acc
  }, {}) as Record<ImageGroup, CarImage[]>
}
