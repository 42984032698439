import type { FetchCarProps } from '@autobid/nuxt-pinia-store/types/Cars'

export const getAuctionsMetaQuery = (
  omits?: FetchCarProps['graphqlQueryOmits']
) => {
  const metadataParams = `, metadataParams: {${
    omits?.suppliers ? '' : 'suppliers: true, '
  }counters: BOOLEAN}`

  const stages = omits?.noFilterMetadataFinishedCars
    ? 'stages: [BEFORE_AUCTION, IN_AUCTION], '
    : ''

  return `meta:items(params: {appIds: $appIds, ${stages} auctionIds: $auctionIds, includeNotVisibleInList: $includeNotVisibleInList, publicationStatus: $publicationStatus, ids: $ids ${metadataParams}}) {
    metadata
  }`
}
