import type { Filters } from '@autobid/nuxt-pinia-store/types/Cars'

export const getDefaultFilterState = () => {
  const result: Filters = {
    category: {},
    stage: {},
    ev: {},
    e174: {},
    e223: {},
    manufacturers: {},
    supplierIds: {},
    e17: {},
    e21from: {},
    e21to: {},
    e24: {},
    e70: {},
    e31: {},
    e68from: {},
    e68to: {},
    e19from: {},
    e19to: {},
    earliestDateOfAddition: {}
  }

  return result
}
